






















































































import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import Datatable from '../components/Datatable.vue';
import Paging from '../components/Paging.vue';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner.vue';
import Input from '@/components/forms/Input.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import PageHeading from '@/components/ui/PageHeading.vue';
import Badge from '@/components/ui/Badge.vue';
import { Breadcrumb } from '@/store/models/breadcrumb.dto';
import Stepper from '@/components/ui/Stepper.vue';
import TableRowActions from '@/components/TableRowActions.vue';
import { IListChannel } from '@/store/models/interfaces/listChannel.interface';

export default Vue.extend({
    name: 'ChannelList',
    components: {
        TableRowActions,
        Datatable,
        Paging,
        Stepper,
        FullPageLoadingSpinner,
        Input,
        Checkbox,
        PageHeading,
        Badge,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            searchTerm: '',
            includeInactive: false,
            breadcrumbs: new Array<Breadcrumb>(),
            tableColumns: [
                {
                    value: 'featured',
                    header: 'FEATURED',
                    type: 'slot',
                    width: '10%',
                },
                {
                    value: 'image',
                    header: '',
                    type: 'slot',
                    width: '38px',
                },
                {
                    value: 'name',
                    header: 'CHANNEL',
                    type: 'text',
                    width: '30%',
                },
                {
                    value: 'description',
                    header: 'DESCRIPTION',
                    type: 'text',
                    width: '35%',
                },
                {
                    value: 'status',
                    header: 'STATUS',
                    type: 'slot',
                    width: '15%',
                },
                {
                    value: 'actions',
                    header: '',
                    type: 'slot',
                    width: '5%',
                    class: 'table-row-actions',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('channel', ['channelListView']),
    },
    async created() {
        this.setBreadcrumbs();
        await this.getChannels({
            q: this.searchTerm,
            includeInactive: this.includeInactive,
            page: this.channelListView.channelList.page,
        });
    },
    mounted() {
        document.getElementById('search')!.focus();
    },
    methods: {
        ...mapActions('channel', ['getChannels']),
        onRowAction(action: string, channel: IListChannel) {
            const actionsMap: Record<string, string> = {
                'edit-channel': 'channel-detail',
            };
            this.$router.push({
                name: actionsMap[action],
                params: { channelCode: channel.code },
            });
        },
        async selectPage(event: Event, page: number) {
            await this.getChannels({
                q: this.searchTerm,
                includeInactive: this.includeInactive,
                page,
            });
        },
        async searchChannels() {
            await this.getChannels({
                q: this.searchTerm,
                includeInactive: this.includeInactive,
                page: this.channelListView.channelList.page,
            });
        },
        rowOnClick(event: Event, item: IListChannel) {
            this.$router.push({
                name: 'convo-list',
                params: { channelCode: item.code },
            });
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Channels', { name: 'all-channels' })];
        },
    },
});
