var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channels-list"},[_c('router-view',{attrs:{"nav-is-opened":_vm.navIsOpened}}),_c('FullPageLoadingSpinner',{attrs:{"show":_vm.$wait.is(_vm.LoadingFlag.ChannelGet)}}),_c('PageHeading',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"Channels"}}),_c('div',{staticClass:"channels-list__search m-b-2"},[_c('Input',{ref:"searchTerm",staticClass:"search w-25",attrs:{"id":"search","type":"text","name":"search","label":"Search channel","has-margin-bottom":false,"has-margin-top":true,"show-clear-icon":true,"placeholder":"Search for a channel here","default-icon":"magnifying-glass"},on:{"input":_vm.searchChannels},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('Checkbox',{attrs:{"id":"includeInactive","label":"Include inactive channels","has-margin-bottom":false,"has-margin-top":true},on:{"input":_vm.searchChannels},model:{value:(_vm.includeInactive),callback:function ($$v) {_vm.includeInactive=$$v},expression:"includeInactive"}})],1),_c('div',{staticClass:"datatable"},[_c('Datatable',{attrs:{"data":_vm.channelListView.channelList,"columns":_vm.tableColumns},on:{"rowClick":_vm.rowOnClick},scopedSlots:_vm._u([{key:"image",fn:function(slotProps){return [_c('span',{staticClass:"channel-image"},[_c('img',{attrs:{"src":slotProps.item.image,"alt":"Channel image"}})])]}},{key:"status",fn:function(slotProps){return [(slotProps.item.status === 'active')?_c('Badge',{attrs:{"type":"success"}},[_vm._v("active")]):_c('Badge',{attrs:{"type":"neutral"}},[_vm._v("inactive")])]}},{key:"featured",fn:function(slotProps){return [(slotProps.item.isFeatured)?_c('span',[_vm._v("★")]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('TableRowActions',{attrs:{"menu-actions":[
                        {
                            label: 'Edit channel',
                            action: 'edit-channel',
                            icon: 'settings',
                        } ]},on:{"action":function($event){return _vm.onRowAction($event, item)}}})]}}])})],1),_c('Paging',{attrs:{"total":_vm.channelListView.channelList.total,"size":_vm.channelListView.channelList.size,"page":_vm.channelListView.channelList.page},on:{"pageSelected":_vm.selectPage}}),_c('Stepper',{attrs:{"show-next-button":"","next-button-help":"","next-button-text":"New Channel","nav-is-opened":_vm.navIsOpened,"is-loading":false},on:{"nextButtonOnClick":function($event){return _vm.$router.push({ name: 'new-channel' })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }